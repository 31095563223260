import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { AbstractComponent } from '@components/generic/abstract.component';
import { MasterProductResource } from '@resources';
import { INPUT_NUMBER_PATTERN_DEC, INPUT_NUMBER_PATTERN_NODEC } from '@constants';

@Component({
  selector: 'app-product-product-form',
  template: require('./product-product-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: MasterProductResource },
  ],
})
export class ProductProductFormComponent extends AbstractComponent implements OnInit {

  public form: FormGroup;
  private dimensions: any;

  @Input() model: IProductForm;

  private static parseFloat(value: any): number {
    return parseFloat(value.toString().replace(',', '.'));
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.buildForm();
    this.fetch();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      weight: [this.dimensions ? this.dimensions.weight : null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      width: [this.dimensions ? this.dimensions.width : null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.required]],
      height: [this.dimensions ? this.dimensions.height : null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.required]],
      length: [this.dimensions ? this.dimensions.length : null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.required]],
      numberOfPieces: [this.dimensions ? this.dimensions.numberOfPieces : null, Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)],
    });
  }

  private fetch(): void {
    this.resource.get(null, { entryPoint: `/v2/master_products/${this.model.masterProduct.id}/dimensions` })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.form.patchValue(response);
      }
    );
  }

  public submit(event?: any): void {
    if (this.state.current.name === 'spare-part.edit') {
      this.submitData(event);
      return;
    }
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => this.submitData(event))
    ;
  }
  public submitData(event?: any): void {
    const id: string = this.model.masterProduct.id;
    const form: any = this.form.value;
    const body: any = {
      weight: form.weight ? ProductProductFormComponent.parseFloat(form.weight) : null,
      width: form.width ? ProductProductFormComponent.parseFloat(form.width) : null,
      height: form.height ? ProductProductFormComponent.parseFloat(form.height) : null,
      length: form.length ? ProductProductFormComponent.parseFloat(form.length) : null,
      numberOfPieces: form.numberOfPieces ? Number(form.numberOfPieces) : null
    };

    this.resource.update(id, body, { entryPoint: `/v2/master_products/${id}/dimensions` })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        if (event && event.doNotNotify) {
          return;
        }
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        if (this.state.current.name === 'spare-part.edit') {
          return;
        }
        if (event && event.redirect) {
          this.state.go('product.edit.content');

          return;
        }

        this.formNotifier.notifyFormSubmitted();

        if (this.state.current.name === 'spare-part.edit.product') {
          this.state.go('spare-part.edit.product', { id: this.model.id });
          return;
        }

        this.state.go('product.edit.content', { id: this.model.id });

      });

  }
}
