'use strict';

export const B2B: string = 'b2b';
export const BRICOPRIVE: string = 'bricoprive';
export const MARKETPLACES_AG: string[] = [
  'sitefr',
  'siteuk',
  'sitees',
  'sitebe',
  'sitenl',
  'siteit',
  'sitept',
  'sitede',
  'sitelu',
  'sitepl'
];

export const MP_NAMES = {
  ALICES_GARDEN: 'AG',
  AMAZON: 'AMA',
  REDOUTE: 'RED',
};
